<template>
  <MainLayout :title="$tc('application.label', 2)" :short-title="$tc('application.label', 2)" back-to="/">
    <template v-slot:menu>
      <ExploreMenu />
    </template>
    <template v-slot:content>
      <div v-if="!applications.length" class="box has-text-centered has-margin-top-large has-padding-large">
        <div class="hero-icon">
          <faicon icon="box-open" size="3x"></faicon>
        </div>
        <div class="hero-content has-margin">{{ $t('noApplications') }}.</div>
        <div class="hero-nav">
          <router-link class="is-primary button" to="/explore/teachers" tag="button"
            ><faicon icon="compass"></faicon> {{ $t('procedure.explore') }}
          </router-link>
        </div>
      </div>
      <div v-else class="panel">
        <div class="panel-header is-hidden-mobile">
          <div class="panel-header-title is-flex">
            <h2>{{ $t('myApplications') }}</h2>
          </div>
        </div>
        <div class="panel-body">
          <RecordApplication
            v-for="application in applications"
            :key="application.code"
            :is-application="true"
            :item="application"
          />
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout'

import ApplicationService from '@/services/ApplicationService'
import ExploreMenu from '@/components/menus/ExploreMenu'
import RecordApplication from '@/components/records/RecordApplication'

export default {
  components: {
    MainLayout,
    ExploreMenu,
    RecordApplication,
  },
  data() {
    return {
      applications: [],
    }
  },
  mounted() {
    this.getApplications()
  },
  methods: {
    async getApplications() {
      this.applications = await ApplicationService.getAll()
    },
  },
}
</script>
